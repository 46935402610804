import React from "react";
import ReactDOM from 'react-dom';

import Elements from "./utils/elements";

import NavigationBar from "./components/navigation/NavigationBar";
import MetaNavigation from "./components/navigation/MetaNavigation";
import NavigationMiniCart from "./components/navigation/NavigationMiniCart";
import MenuMobile from "./components/navigation/MenuMobile";
import Dropdown,{createDropdownItem} from "./components/dropdown/Dropdown";
import SelectBox from "./components/dropdown/Selectbox";
import SelectboxNoInput from "./components/dropdown/SelectboxNoInput";
import InputSpinner from "./components/InputSpinner";
import SingleActionButton from "./components/button/SingleActionButton";
import FlyoutWithText from "./components/button/FlyoutWithText";
import PrimaryButton from "./components/button/PrimaryButton";
import SecondaryButton from "./components/button/SecondaryButton";

window.addEventListener("DOMContentLoaded", (event) => {
    let metaNavigationElement = Elements.getMetaNavigationElement();
    let navigationElement = Elements.getNavigationElement();
    let navigationMiniCartElement = Elements.getMiniCartElement();
    let navigationMenuMobile = Elements.getMenuMobile();
    let dropdownElement = Elements.getDropdownElement();
    let selectboxElement = Elements.getSelectboxElement();
    let selectBoxNoInputElement = Elements.getSelectboxNoInputElement();
    let inputSpinnerElements = Elements.getInputSpinnerElementOnList();
    let singleActionButtonElement = Elements.getSingleActionButtonElement();
    let flyoutWithText = Elements.getFlyoutWithText();
    let primaryButton = Elements.getPrimaryButton();
    let secondaryButton = Elements.getSecondaryButton();


    if (metaNavigationElement) {
        ReactDOM.render(<MetaNavigation />, metaNavigationElement);
    }

    if (navigationElement) {
        ReactDOM.render(<NavigationBar />, navigationElement);
    }

    if (navigationMiniCartElement) {
        ReactDOM.render(<NavigationMiniCart />, navigationMiniCartElement);
    }

    if (navigationMenuMobile) {
        ReactDOM.render(<MenuMobile />, navigationMenuMobile);
    }

    if (dropdownElement) {
        const initialState = "Aktion Auswählen";
        const dropdownItems = [
            createDropdownItem("Warenkorb leeren", "#", "request", true),
            createDropdownItem("Aus Warenkorb entfernen", "#", "test", true),
            createDropdownItem("Artikel verschieben", "#", "test", true),
        ];
        ReactDOM.render(
            <Dropdown initialState={initialState} dropdownItems={dropdownItems} />,
            dropdownElement
        );
    }

    if (selectboxElement) {
        ReactDOM.render(<SelectBox />, selectboxElement);
    }

    if (selectBoxNoInputElement) {
        ReactDOM.render(<SelectboxNoInput />, selectBoxNoInputElement);
    }

    if (inputSpinnerElements) {
        // Loop through each element and render the React component
        inputSpinnerElements.forEach((element) => {
            const dataId = element.getAttribute('data-id');
            const productId = element.getAttribute('data-product-id');
            const ve = element.getAttribute('data-ve');
            const productAttributeDawn = element.getAttribute('data-product-attribute-dawn');
            const qty = element.getAttribute('data-qty');
            const itemId = element.getAttribute('data-item-id');
            const forceLoad =  !! element.getAttribute('data-force-load');
            const name = element.getAttribute('data-name');

            ReactDOM.render(
                <InputSpinner
                    id={dataId}
                    productId={productId}
                    ve={ve}
                    productAttributeDawn={productAttributeDawn}
                    itemId={itemId}
                    input={qty}
                    forceLoadingComponent={forceLoad}
                    name={name}
                />,
                element
            );
        });
    }

    if (singleActionButtonElement) {
        ReactDOM.render(<SingleActionButton />, singleActionButtonElement);
    }

    if (flyoutWithText) {
        ReactDOM.render(<FlyoutWithText />, flyoutWithText);
    }

    if (primaryButton) {
        ReactDOM.render(<PrimaryButton />, primaryButton);
    }

    if (secondaryButton) {
        ReactDOM.render(<SecondaryButton />, secondaryButton);
    }
});

document.addEventListener('open-modal-finish', function (event) {
    let inputSpinnerElement = Elements.getInputSpinnerElement();

    if (inputSpinnerElement) {
        const dataId = inputSpinnerElement.getAttribute('data-id');
        const productId = inputSpinnerElement.getAttribute('data-product-id');
        const ve = inputSpinnerElement.getAttribute('data-ve');
        const productAttributeDawn = inputSpinnerElement.getAttribute('data-product-attribute-dawn');

        ReactDOM.render(
            <InputSpinner
                id={dataId}
                productId={productId}
                ve={ve}
                productAttributeDawn={productAttributeDawn}
                input={event.detail.qty}
                button={event.detail.button}
            />,
            inputSpinnerElement
        );
    }
});