const Elements = {

    getMetaNavigationElement() {
        return document.getElementById('jsReactMetaNav');
    },

    getNavigationElement() {
        return document.getElementById('jsReactNav');
    },

    getMenuMobile() {
        return document.getElementById('jsReactMenuMobile');
    },

    getMiniCartElement() {
        return document.getElementById('jsReactNavMiniCart');
    },

    getInputSpinnerElementOnList() {
        return document.querySelectorAll('.jsReactInputSpinner');
    },

    getInputSpinnerElement() {
        return document.querySelector('.modal #jsReactInputSpinner');
    },

    getModalElement() {
        return document.getElementById('jsReactModal');
    },

    getDropdownElement() {
        return document.getElementById('jsDropdown');
    },

    getSelectboxElement() {
        return document.getElementById('jsSelectbox');
    },

    getSelectboxNoInputElement() {
        return document.getElementById('jsSelectboxNoInput');
    },

    getSingleActionButtonElement() {
        return document.getElementById('jsSingleActionButton');
    },

    getFlyoutWithText() {
        return document.getElementById('jsFlyoutWithText');
    },

    getPrimaryButton() {
        return document.getElementById('jsPrimaryButton');
    },

    getSecondaryButton() {
        return document.getElementById('jsSecondaryButton');
    }
};

export default Elements;