import _get from 'lodash.get';

import { config } from '../config';

const LocalStorage = {
    getMagentoLocalStorage() {
        const tokenSource = _get(config, 'storageSource.token', {});

        //if (!LocalStorage.isBrowser()) {
        //    return {};
        //}

        return JSON.parse(
            window.localStorage.getItem(tokenSource.storageKey) || '{}'
        );
    },

    getPermissionResolver() {
        const source = _get(config, 'storageSource.permissionResolver', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    getPermissionFromLocalStorage(key) {
        return _get(LocalStorage.getPermissionResolver(), key);
    },

    getCustomer() {
        const source = _get(config, 'storageSource.customer', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    getCustomerFromLocalStorage(key) {
        return _get(LocalStorage.getCustomer(), key);
    },

    getCartResolver() {
        const source = _get(config, 'storageSource.cartResolver', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    getCartResolverFromLocalStorage(key) {
        return _get(LocalStorage.getCartResolver(), key);
    },

    getCart() {
        const source = _get(config, 'storageSource.cart', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    getCartFromLocalStorage(key) {
        return _get(LocalStorage.getCart(), key);
    },

    getRfq() {
        const source = _get(config, 'storageSource.rfqResolver', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    getRfqFromLocalStorage(key) {
        return _get(LocalStorage.getRfq(), key);
    },

    getWishlist() {
        const source = _get(config, 'storageSource.wishlistResolver', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    getWishlistFromLocalStorage(key) {
        return _get(LocalStorage.getWishlist(), key);
    },

    getWishlistResolver() {
        const source = _get(config, 'storageSource.wishlistResolver', {});

        return _get(LocalStorage.getMagentoLocalStorage(), source.value);
    },

    getWishlistResolverFromLocalStorage(key) {
        return _get(LocalStorage.getWishlistResolver(), key);
    },

    getCartApprovalPermission() {
        return this.getPermissionFromLocalStorage('cart_approval');
    },

    getCartApprovalRequestPermission() {
        return this.getPermissionFromLocalStorage('cart_approval_request');
    },

    getRioScanPermission() {
        return this.getPermissionFromLocalStorage('RIOSCAN');
    },

    getCartDirectContinuePermission() {
        return this.getPermissionFromLocalStorage('cart_direct_continue');
    },

    getCartOfferPermission() {
        return this.getPermissionFromLocalStorage('cart_offer');
    },

    checkIfIsLoggedIn() {
        return this.getPermissionFromLocalStorage('is_logged_in');
    },

    getFaqPermission() {
        return this.getPermissionFromLocalStorage('help');
    },

    getCartRequestPermission() {
        return this.getPermissionFromLocalStorage('cart_request');
    },

    getCartPermission() {
        return this.getPermissionFromLocalStorage('cart');
    },

    getArticleCheckAvailabilityPermission() {
        return this.getPermissionFromLocalStorage('article_check_availability');
    },

    getNumberOfPiecesPermission() {
        return this.getPermissionFromLocalStorage('STUECKZAHL');
    },

    getAccountPermission() {
        return this.getPermissionFromLocalStorage('account');
    },

    getOrderHistoryPermission() {
        return this.getPermissionFromLocalStorage('order_history');
    },

    getAdminPermission() {
        return this.getPermissionFromLocalStorage('admin');
    },

    getLabelDesignerPermission() {
        return this.getPermissionFromLocalStorage('label_designer');
    },

    checkIfOciUser() {
        return this.getPermissionFromLocalStorage('is_oci_user');
    },

    getArticleCustomSkuEditPermission() {
        return this.getPermissionFromLocalStorage('article_custom_sku_edit');
    },

    getFavoritePermission() {
        return this.getPermissionFromLocalStorage('favorites');
    },

    getUserName() {
        return this.getCustomerFromLocalStorage('username');
    },

    getCartSummaryCount() {
        return this.getCartFromLocalStorage('summary_count');
    },

    getAllCarts() {
        return this.getCartResolverFromLocalStorage('carts');
    },

    getAllWishlists() {
        return this.getWishlistResolverFromLocalStorage('wishlists');
    },

    getWishlists() {
        return this.getWishlistFromLocalStorage('wishlists');
    },

    getRfqCartCount() {
        return this.getRfqFromLocalStorage('rfqCartCount');
    }
};

export default LocalStorage;
